import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SignalEditDialogHeader } from "./SignalEditDialogHeader";

import { SignalEditForm } from "./SignalEditForm";

export function SignalEditDialog({
  id,
  show,
  onHide,
  signal,
  fetchData,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
  }, [id, dispatch]);

  const saveCustomer = (customer) => {
    if (!id) {
    } else {
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SignalEditDialogHeader id={signal?._id} />
      <SignalEditForm
        fetchData={fetchData}
        saveCustomer={saveCustomer}
        actionsLoading={false}
        signal={signal || {}}
        onHide={onHide}
      />
    </Modal>
  );
}