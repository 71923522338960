import React, { useState, useRef, useEffect } from "react";

import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import { RewardRuleEditDialog } from "./EditRewardRule/RewardRuleEditDialog";
// import { RewardRuleDeleteDialog } from "./EditRewardRule/RewardRuleDeletDialog";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index";
import { SignalDeleteDialog } from "./Signal/SignalDeletDialog";
import { SignalEditDialog } from "./Signal/SignalEditDialog";

export const CryptoSignalsTableComponent = ({
  dataSource,
  fetchData,
  count,
  showSearch,
}) => {
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchedColumn, setSearchedColumn] = useState("");

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [signal, setSignal] = useState(false);

  const searchInput = useRef(null);

  useEffect(() => {
    fetchData("", pageIndex, 10);
  }, [pageIndex]);

  const columns = [
    {
      text: "ID",
      dataIndex: "_id",
      dataField: "_id",
      key: "_id",
    },
    {
      text: "Pair",
      dataIndex: "pair",
      dataField: "pair",
      formatter: (cell, row) => {
        return cell?.value1 + "/" + cell?.value2 ?? "-";
      },
    },
    {
      text: "Feed",
      dataIndex: "feed",
      dataField: "feed",
    },
    {
      text: "Direction",
      dataIndex: "direction",
      dataField: "direction",
      key: "direction",
    },
    {
      text: "Entry",
      dataIndex: "entry",
      dataField: "entry",
      key: "entry",
      formatter: (cell, row) => {
        return cell?.value1 + " - " + cell?.value2;
      },
    },
    {
      text: "Target 1",
      dataIndex: "targets",
      dataField: "targets",
      key: "targets",
      formatter: (cell, row) => {
        return cell?.target1;
      },
    },
    {
      text: "Target 2",
      dataIndex: "targets",
      dataField: "targets",
      key: "targets",
      formatter: (cell, row) => {
        return cell?.target2 ?? "-";
      },
    },
    {
      text: "Target 3",
      dataIndex: "targets",
      dataField: "targets",
      key: "targets",
      formatter: (cell, row) => {
        return cell?.target3 ?? "-";
      },
    },
    {
      text: "Target 4",
      dataIndex: "targets",
      dataField: "targets",
      key: "targets",
      formatter: (cell, row) => {
        return cell?.target4 ?? "-";
      },
    },
    {
      text: "Stoploss",
      dataIndex: "stoploss",
      dataField: "stoploss",
      key: "stoploss",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setSignal(row);
                setShow(true);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
            <> </>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                setSignal(row);
                setShowDelete(true);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </>
        );
      },
    },
  ];

  const handleChange = async (page) => {
    setPageIndex(page);
  };

  return (
    <div>
      {show && (
        <SignalEditDialog
          show={show}
          signal={signal}
          setSignal={setSignal}
          onHide={() => {
            setShow(false);
          }}
          fetchData={fetchData}
        />
      )}
      {showDelete && (
        <SignalDeleteDialog
          show={showDelete}
          signal={signal}
          setSignal={setSignal}
          fetchData={fetchData}
          onHide={() => {
            setShowDelete(false);
            setSignal(null);
          }}
        />
      )}

      <Card>
        <CardHeader title="Crypto Signals List">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShow(true);
                setSignal(null);
              }}
            >
              New Signal
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory({
              // onChange={handleChange},
              custom: true,
              totalSize: count,
              sizePerPageList: [{ text: "10", value: 10 }],
              sizePerPage: pageSize,
              onPageChange: (page) => {
                handleChange(page);
              },
              page: pageIndex,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={true}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    columns={columns}
                    dataSource={dataSource}
                    data={dataSource ? dataSource : []}
                    onTableChange={() => {}}
                    remote
                    keyField="_id"
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};