import Axios from "axios";
import React, { useEffect, useState } from "react";
import { CryptoSignalsTableComponent } from "../components/crypto-signals/crypto-signals";
import { UsersTableComponent } from "../components/user-whitelist/user-whitelist";

export function UsersListing() {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`);

      console.log(res);
      const data = await res.json();
      console.log(data.data.data);
      setData(data.data.data);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UsersTableComponent
      dataSource={data}
      fetchData={fetchData}
      count={totalRecords}
      showSearch={true}
    />
  );
}
