import React, { useState, useRef, useEffect } from "react";

import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import { RewardRuleEditDialog } from "./EditRewardRule/RewardRuleEditDialog";
// import { RewardRuleDeleteDialog } from "./EditRewardRule/RewardRuleDeletDialog";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index";
// import { SignalDeleteDialog } from "./Signal/SignalDeletDialog";
// import { SignalEditDialog } from "./Signal/SignalEditDialog";

export const UsersTableComponent = ({
  dataSource,
  fetchData,
  count,
  showSearch,
}) => {
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchedColumn, setSearchedColumn] = useState("");

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [signal, setSignal] = useState(false);

  const searchInput = useRef(null);

  useEffect(() => {
    fetchData("", pageIndex, 10);
  }, [pageIndex]);

  const columns = [
    {
      text: "ID",
      dataIndex: "_id",
      dataField: "_id",
      key: "_id",
    },

    {
      text: "address",
      dataIndex: "publicAddress",
      dataField: "publicAddress",
      key: "publicAddress",
    },

    {
      text: "isWhitelisted",
      dataIndex: "isWhitelisted",
      dataField: "isWhitelisted",
      key: "isWhitelisted",
      formatter: (cell, roe) =>
        cell === true ? (
          <span className="label label-lg label-light-info label-inline">
            True
          </span>
        ) : (
          <span className="label label-lg label-light-danger label-inline">
            False
          </span>
        ),
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <>
            <button
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                fetch(
                  `${process.env.REACT_APP_API_ENDPOINT}/users/whitelist/${row.publicAddress}`,
                  {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json" },
                  }
                ).then(() => {
                  fetchData();
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}
                />
              </span>
            </button>
            <> </>
          </>
        );
      },
    },
  ];

  const handleChange = async (page) => {
    setPageIndex(page);
  };

  return (
    <div>
      <Card>
        <CardHeader title="Crypto Signals List">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShow(true);
                setSignal(null);
              }}
            >
              New Signal
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory({
              // onChange={handleChange},
              custom: true,
              totalSize: count,
              sizePerPageList: [{ text: "10", value: 10 }],
              sizePerPage: pageSize,
              onPageChange: (page) => {
                handleChange(page);
              },
              page: pageIndex,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={true}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    columns={columns}
                    dataSource={dataSource}
                    data={dataSource ? dataSource : []}
                    onTableChange={() => {}}
                    remote
                    keyField="_id"
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
