import Axios from "axios";
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

export function SignalDeleteDialog({
  id,
  show,
  onHide,
  signal,
  fetchData,
}) {
  // Customers Redux state
  const dispatch = useDispatch();

 
  useEffect(() => {
  }, [id]);

  useEffect(() => {}, [dispatch]);

  const deleteData = async () => {
    try {
      // await Axios.delete(
      //   `${process.env.REACT_APP_API_ENDPOINT}/reward-rule/${rewardRule._id}`
      // );
      // fetchData();
      // onHide();

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/crypto-signals/${signal._id}`, { method: 'DELETE' })
        .then(() => {
          fetchData()
          onHide();
        });

    } catch (ex) {
      
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}

      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Delete Crypto Signal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to permanently delete this Signal Record?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteData}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
