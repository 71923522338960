// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "./../../../../_metronic/_partials/controls";
import Axios from "axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function SignalEditForm({
  saveCustomer,
  signal,
  setServiceBox,
  actionsLoading,
  onHide,
  fetchData,
}) {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={signal}
        onSubmit={async (values) => {
         
          if (signal._id) {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/crypto-signals/${signal._id}`, { method: 'PATCH',headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values) })
            .then(() => {
              fetchData()
              onHide();
            });
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/crypto-signals`, { method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values) })
            .then(() => {
              fetchData()
              onHide();
            });
          }
          fetchData();
          onHide();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
              <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-3">
                  <Field
                      as={Select}
                      name="direction"
                      placeholder="Direction"
                      label="Direction"
                      className="form-control"
                    >
                      <option value="BUY">Buy</option>
                      <option value="SELL">Sell</option>
                    </Field>
                  </div>
                  <div className="col-lg-3">
                  <Field
                      as={Select}
                      name="feed"
                      placeholder="Feed"
                      label="Feed"
                      className="form-control"
                    >
                      <option value="SPOT">SPOT</option>
                      <option value="FUTURE">FUTURE</option>
                    </Field>
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="entry.value1"
                      component={Input}
                      placeholder="Entry Value 1"
                      label="Entry Value 1"
                      type="number"
                    />
                  </div>

                  <div className="col-lg-3">
                    <Field
                      name="entry.value2"
                      component={Input}
                      placeholder="Entry Value 2"
                      label="Entry Value 2"
                      type="number"
                    />
                  </div>

                  
              </div>

              <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-3">
                  <Field
                      name="targets.target1"
                      component={Input}
                      placeholder="Target value 1"
                      label="Target value 1"
                      className="form-control"
                      type="number"
                    >
                    
                    </Field>
                  </div>
                  <div className="col-lg-3">
                  <Field
                      name="targets.target2"
                      component={Input}
                      placeholder="Target Value 2"
                      label="Target Value 2"
                      className="form-control"
                      type="number"
                    >
                      
                    </Field>
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="targets.target3"
                      component={Input}
                      placeholder="Target Value 3"
                      label="Target Value 3"
                      type="number"
                    />
                  </div>

                  <div className="col-lg-3">
                    <Field
                      name="targets.target4"
                      component={Input}
                      placeholder="Target Value 4"
                      label="Target Value 4"
                      type="number"
                    />
                  </div>                  
              </div>

              <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-3">
                  <Field
                      name="stoploss"
                      component={Input}
                      placeholder="Stoploss"
                      label="Stoploss"
                      className="form-control"
                      type="number"
                    >
                    
                    </Field>
                  </div>
                  <div className="col-lg-3">
                  <Field
                      name="pair.value1"
                      component={Input}
                      placeholder="Pair 1"
                      label="Pair 1"
                      className="form-control"
                    >
                      
                    </Field>
                  </div>

                  <div className="col-lg-3">
                  <Field
                      name="pair.value2"
                      component={Input}
                      placeholder="Pair 2"
                      label="Pair 2"
                      className="form-control"
                    >
                      
                    </Field>
                  </div>
                             
              </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}