
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { CryptoSignalsTableComponent } from "../components/crypto-signals/crypto-signals";


export function SignalListing() {

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);

  const fetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/crypto-signals`
      );
      const data = await res.json()
      setData(data.data.data)
      
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


    return <CryptoSignalsTableComponent
    dataSource={data}
    fetchData={fetchData}
    count={totalRecords}
    showSearch={true}
    />
}